<template>
  <div>
    <el-card class="box-card" style="padding: 20px">
      <div style="margin-bottom: 30px"><i class="card-line"></i>
        查看公告
        <el-button type="warning" style="float: right" size="mini" @click="$router.go(-1)">返回</el-button>
      </div>
      <div style="text-align: center;font-size: 20px;letter-spacing: 2px;font-weight: 600;margin-bottom: 20px">{{valueName}}</div>

      <div style="border: 1px solid #f2f2f2;height: 700px;overflow: auto;padding: 20px">
        <div v-html="valueEdit"></div>
      </div>
    </el-card>
  </div>
</template>

<script>
import WangEditor from "@/components/WangEnduit";
import { defineComponent, reactive, toRefs, ref, onMounted } from "vue";
import { addAnnouncement, announcementById, schoolList, update } from "./noticeApi";
import { useRoute, useRouter } from "vue-router";
import { ElMessage } from 'element-plus'

export default defineComponent({
  name: "addNotice",
  components: {
    WangEditor,
  },
  setup() {
    let route = useRoute()
    let router = useRouter()
    let valueDo = ref()
    let data = reactive({
      valueName: '',
      valueEdit: '',
      selectSchool: '',
      schoolList: [],
      type: '0',
      action:true,
    })
    onMounted(() => {
      if (route.query.id) {
        data.type = '1'
        getMyNotice()
      }
      getSchool()
    })
    let getMyNotice = () => {
      announcementById({ id: route.query.id,action:data.action }).then(res => {
        if (res.data.code === '200') {
          data.valueName = res.data.data.announcement.announcementName
          data.valueEdit = res.data.data.announcement.announcementContent
          data.selectSchool = res.data.data.announcement.schoolId
        }
      })
    }
    let getSchool = () => {
      schoolList().then(res => {
        if (res.data.code === '200') {
        }
      })
    }
    let valueChange = (value) => {
      if (value) data.valueEdit = value
    }

    let updataNotice = () => {
      // if (!data.selectSchool) return ElMessage.warning('请先选择学校')
      if (!data.valueName) return ElMessage.warning('请输入报告名称')
      if (data.type === '0') {
        let parame = {
          "announcementName": data.valueName,
          "announcementContent": data.valueEdit,
          "publisher": JSON.parse(sessionStorage.getItem('user')).username,
          "schoolId": "50000000000000"
        }
        addAnnouncement(parame).then(res => {
          if (res.data.code === '200') {
            ElMessage.success(res.data.msg)
            router.push('/noticeManage')
          } else {
            ElMessage.error(res.data.msg)
          }
        })
      } else {
        let parame = {
          id: route.query.id,
          "announcementName": data.valueName,
          "announcementContent": data.valueEdit,
          "publisher": JSON.parse(sessionStorage.getItem('user')).username,
          "schoolId": "50000000000000"
        }
        update(parame).then(res => {
          if (res.data.code === '200') {
            ElMessage.success(res.data.msg)
            router.push('/noticeManage')
          } else {
            ElMessage.error(res.data.msg)
          }
        })
      }

    }

    return {
      ...toRefs(data),
      valueDo,
      valueChange,
      updataNotice,
    }
  }
})
</script>

<style scoped>
.card-line {
  display: inline-block;
  width: 3px;
  height: 13px;
  border-radius: 8px;
  margin-right: 10px;
  background: #faad14;
}
.report-title {
  margin-left: 20px;
  color: #000000;
  font-weight: 500;
  font-size: 13px;
  margin-right: 10px;
}
</style>